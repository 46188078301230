export const colorSchema = {
    G: '#02b3e4',
    SV: "#e91e63", 
    CS: '#fa750f',
    PF: '#1b03fc',
    PW: '#607d8b',
    T: '#7d4d3e',
    A: '#4caf50',
    GE: "#e11F63",
    P: "#f21e37",
    CL: '#175442',
    BS: '#815499',
    D: '#7761f2',
    C: '#d8e339',
    CC: '#3107d9',
    BP: '#236b44',
    MA: '#0366fc',
}

export const semanticTypeShorthand = {
    Gene: "G",
    SequenceVariant: "SV",
    ChemicalSubstance: "CS",
    PhenotypicFeature: "PF",
    Pathway: "PW",
    Transcript: "T",
    AnatomicalEntity: "A",
    GenomicEntity: "GE",
    Protein: "P",
    CellLine: "CL",
    Biosample: "BS",
    Disease: "D",
    Cell: "C",
    CellularComponent: "CC",
    BiologicalProcess: "BP",
    MolecularActivity: "MA"
}